<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">{{$t('breadCrumb.mainPage')}}</el-breadcrumb-item>
      <el-breadcrumb-item>{{$t('breadCrumb.customer')}}</el-breadcrumb-item>
      <el-breadcrumb-item>{{$t('breadCrumb.customerList')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card>
      <!-- 搜索 添加 -->
      <el-form
        :model="queryInfo"
        label-width="140px"
        :inline="true"
      >
        <el-form-item :label="$t('customer.customerName')">
          <el-input :placeholder="$t('customerPage.customerNamePlaceholder')"
                    v-model.trim="queryInfo.customerName"
                    clearable>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('customer.delFlag')">
          <el-select v-model="queryInfo.delFlag"
                     clearable
                     size="small" :placeholder="$t('customerPage.customerStatusPlaceholder')">
            <el-option v-for="flag in delFlagDicList" :key="flag.key" :value="flag.key" :label="flag.name" > {{ flag.name }} </el-option>
          </el-select>
        </el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="getCustomerList">{{$t('common.search')}}</el-button>
        <el-button type="warning" @click="resetSearch">{{$t('common.reset')}}</el-button>
      </el-form>
      <el-button type="primary" @click="addHandle">{{$t('common.add')}}</el-button>
      <!-- 用户列表区域 -->
      <el-table :data="customerList" border stripe>
        <!-- stripe: 斑马条纹
        border：边框-->
        <el-table-column type="index" :label="$t('common.sn')" width="60px;"></el-table-column>
        <el-table-column prop="customerId" label="customerId" v-if="false"></el-table-column>
        <el-table-column prop="customerName" :label="$t('customer.customerName')" width="200px;"></el-table-column>
        <el-table-column v-if="globalParameters.verType === 'platform'" prop="pcustomer.customerName" :label="$t('customer.pCustomerName')" width="200px;" ></el-table-column>
        <el-table-column prop="contactPhone" :label="$t('customer.contactPhone')" width="160px;"></el-table-column>
        <el-table-column prop="quota" :label="$t('customer.quota')" width="140px;"></el-table-column>
        <el-table-column prop="customerType" :label="$t('customer.customerType')" width="120px;" :formatter="customerTypeFormatter"></el-table-column>
        <el-table-column prop="contactUser" :label="$t('customer.contactUser')" width="100px;"></el-table-column>
        <el-table-column prop="address" :label="$t('customer.address')" width="260px;"></el-table-column>
        <el-table-column prop="delFlag" :label="$t('customer.delFlag')" width="90px;" :formatter="delFlagFormatter"></el-table-column>
        <el-table-column prop="createTime" :label="$t('customer.createTime')" width="150px;"></el-table-column>
        <el-table-column :label="$t('common.operation')" width="100px;">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              circle
              @click="editCustomer(scope.row.customerId)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              circle
              v-if="scope.row.customerId !== selfCustomerId"
              @click="removeCustomerById(scope.row.customerId)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNum"
        :page-sizes="[20, 50, 100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>

    <!-- 添加用户的对话框 -->
    <el-dialog :title="$t('customerPage.addDiagTitle')" :visible.sync="addDialogVisible" width="40%" @close="addDialogClosed">
      <!-- 内容主体 -->
      <el-form
        :model="addCustomerForm"
        ref="addCustomerFormRef"
        :rules="addCustomerFormRules"
        label-width="150px"
        label-position="left"
      >
        <el-form-item label="customerId" v-if="false">
          <el-input v-model="addCustomerForm.customerId" v-if="false"></el-input>
        </el-form-item>
        <el-form-item :label="$t('customer.customerName')" prop="customerName">
          <el-input v-model.trim="addCustomerForm.customerName" :disabled="addCustomerForm.customerId !== undefined"></el-input>
        </el-form-item>
        <el-form-item v-if="globalParameters.verType === 'platform'" :label="$t('customer.customerType')" prop="customerType">
          <el-select
            v-model="addCustomerForm.customerType"
            :placeholder="$t('customerPage.customerTypePlaceholder')"
            size="small"
            :disabled="addCustomerForm.customerId !== undefined"
          >
            <el-option v-for="dic in customerTypeList" :key="parseInt(dic.codeValue)" :value="parseInt(dic.codeValue)" :label="dic.codeName" > {{ dic.codeName }} </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('customer.quota')" prop="quota">
          <el-input type="number" v-model="addCustomerForm.quota"></el-input>
        </el-form-item>
        <el-form-item :label="$t('customer.contactPhone')" prop="contactPhone">
          <el-input v-model.trim="addCustomerForm.contactPhone"></el-input>
        </el-form-item>
        <el-form-item :label="$t('customer.contactUser')" prop="contactUser">
          <el-input v-model.trim="addCustomerForm.contactUser"></el-input>
        </el-form-item>
        <el-form-item :label="$t('customer.address')" prop="address">
          <el-input v-model.trim="addCustomerForm.address"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">{{$t('common.cancel')}}</el-button>
        <el-button type="primary" @click="addCustomer">{{$t('common.confirm')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { listCustomer, insertCustomer, updateCustomer, deleteCustomer, getCustomerByCustomerId } from '@/api/customer'
import { checkLogin } from '@/api/globalProcessErr'
import { listCodeByClass } from '@/api/code'
import { globalParameters } from '@/common/globalVal'

export default {
  data () {
    let checkQuota = (rule, value, callback) => {
      if (!value || value <= 0) {
        return callback(new Error(this.$t('customerPage.rules.quota.quotaMin') + ''))
      }
    }
    return {
      // 获取用户列表查询参数对象
      queryInfo: {
        customerName: '',
        delFlag: 0,
        // 当前页数
        pageNum: 1,
        // 每页显示多少数据
        pageSize: 20
      },
      delFlagDicList: [
        { key: 0, name: 'Normal' },
        { key: 1, name: 'Deleted' }
      ],
      customerList: [],
      customerTypeList: [],
      selfCustomerId: undefined,
      total: 0,
      userId: undefined,
      addDialogVisible: false,
      checkQuota: checkQuota,
      addCustomerForm: {
        customerId: undefined,
        customerName: '',
        customerType: undefined,
        contactPhone: '',
        contactUser: '',
        address: '',
        quota: undefined
      },
      globalParameters: globalParameters
    }
  },
  computed: {
    addCustomerFormRules () {
      return {
        customerName: [
          { required: true, message: this.$t('customerPage.rules.customerName.required'), trigger: 'blur' },
          {
            min: 4,
            max: 30,
            message: this.$t('customerPage.rules.customerName.length'),
            trigger: 'blur'
          }],
        customerType: [
          { required: true, message: this.$t('customerPage.rules.customerType.required'), trigger: 'blur' }
        ],
        quota: [
          { required: true, message: this.$t('customerPage.rules.quota.required'), trigger: 'blur' },
          { type: 'number', message: this.$t('customerPage.rules.quota.type'), trigger: 'blur', transform: (value) => Number(value) },
          { validator: this.checkQuota, trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.$i18n.locale = window.sessionStorage.getItem('lang')
    this.userId = parseInt(window.sessionStorage.getItem('userId'))
    this.selfCustomerId = parseInt(window.sessionStorage.getItem('selfCustomerId'))
    this.getCustomerList()
    this.getCustomerTypeDicList()
  },
  methods: {
    getCustomerList () {
      const self = this
      listCustomer(this.queryInfo).then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return self.$message.error(res.data.msg)
        }
        self.customerList = res.data.data.list
        self.total = res.data.data.total
      }).catch(err => {
        return self.$message.error(err.message)
      })
    },
    getCustomerTypeDicList () {
      const _this = this
      listCodeByClass('CUSTOMER_TYPE').then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return this.$message.error(res.data.msg)
        }
        _this.customerTypeList = res.data.data
      }).catch(err => {
        return this.$message.error(err.message)
      })
    },
    // 监听 pagesize改变的事件
    handleSizeChange (newSize) {
      this.queryInfo.pageSize = newSize
      this.getCustomerList()
    },
    // 监听 页码值 改变事件
    handleCurrentChange (newPage) {
      this.queryInfo.pageNum = newPage
      this.getCustomerList()
    },
    addHandle () {
      this.addCustomerForm = {
        customerId: undefined,
        customerName: '',
        customerType: undefined,
        contactPhone: '',
        contactUser: '',
        address: '',
        quota: undefined
      }
      this.addDialogVisible = true
    },
    // 监听 添加用户对话框的关闭事件
    addDialogClosed () {
      this.$refs.addUserFormRef.resetFields()
      this.addCustomerForm = {
        customerId: undefined,
        customerName: '',
        contactPhone: '',
        contactUser: '',
        address: '',
        quota: undefined
      }
    },
    delFlagFormatter (row, column) {
      if (row.delFlag === 1) {
        return 'Deleted'
      } else {
        return 'Normal'
      }
    },
    customerTypeFormatter (row, column) {
      let customerType = row.customerType === null ? '' : (row.customerType === '' ? '' : row.customerType + '')
      for (let i = 0; i < this.customerTypeList.length; i++) {
        if (customerType === this.customerTypeList[i].codeValue) {
          return this.customerTypeList[i].codeName
        }
      }
    },
    editCustomer (customerId) {
      getCustomerByCustomerId(customerId).then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return this.$message.error(res.data.msg)
        }
        this.addCustomerForm = res.data.data
      }).catch(err => {
        return this.$message.error(err.message)
      })
      this.addDialogVisible = true
    },
    // 添加用户
    addCustomer () {
      // 提交请求前，表单预验证
      const self = this
      const formData = self.addCustomerForm
      if (formData.customerId !== undefined) {
        updateCustomer(formData).then(res => {
          checkLogin(res)
          if (res.data.code !== 0) {
            return self.$message.error(res.data.msg)
          }
          self.$message.success(self.$t('common.updateSuccess'))
          this.getCustomerList()
        }).catch(err => {
          return self.$message.error(err.message)
        })
      } else {
        insertCustomer(formData).then(res => {
          checkLogin(res)
          if (res.data.code !== 0) {
            return self.$message.error(res.data.msg)
          }
          self.$message.success(self.$t('common.createSuccess'))
          this.getCustomerList()
        }).catch(err => {
          return self.$message.error(err.message)
        })
      }
      this.addDialogVisible = false
    },
    resetSearch () {
      this.queryInfo.customerName = ''
      this.queryInfo.delFlag = 0
      this.queryInfo.pageSize = 20
      this.queryInfo.pageNum = 1
      this.getCustomerList()
    },
    async removeCustomerById (id) {
      const self = this
      const req = {
        customerId: id
      }
      const confirmResult = await this.$confirm(
        self.$t('common.deleteConfirm'),
        self.$t('common.notice'),
        {
          confirmButtonText: self.$t('common.confirm'),
          cancelButtonText: self.$t('common.cancel'),
          type: 'warning'
        }
      ).catch(err => err)
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== 'confirm') {
        return this.$message.info(self.$t('common.canceled'))
      }
      deleteCustomer(req).then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return self.$message.error(res.data.msg)
        }
        self.$message.success(self.$t('common.deleteSuccess'))
        this.getCustomerList()
      })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
