import request from '@/api/request'

export function listCustomer (data) {
  const req = {
    customerName: data.customerName,
    delFlag: data.delFlag,
    pageSize: data.pageSize,
    pageNum: data.pageNum
  }
  return request({
    url: 'customer/listCustomer',
    method: 'post',
    data: req
  })
}

export function insertCustomer (data) {
  const req = {
    customerName: data.customerName,
    contactPhone: data.contactPhone,
    contactUser: data.contactUser,
    address: data.address,
    customerType: data.customerType,
    quota: data.quota
  }
  return request({
    url: 'customer/insertCustomer',
    method: 'post',
    data: req
  })
}

export function updateCustomer (data) {
  const req = {
    customerId: data.customerId,
    customerName: data.customerName,
    contactPhone: data.contactPhone,
    contactUser: data.contactUser,
    address: data.address,
    quota: data.quota
  }
  return request({
    url: 'customer/updateCustomer',
    method: 'post',
    data: req
  })
}

export function deleteCustomer (data) {
  const req = {
    customerId: data.customerId
  }
  return request({
    url: 'customer/deleteCustomer',
    method: 'post',
    data: req
  })
}

export function getCustomerByCustomerId (customerId) {
  return request({
    url: 'customer/getCustomerByCustomerId/' + customerId,
    method: 'get'
  })
}

export function listAllCustomer () {
  return request({
    url: 'customer/listAllCustomer',
    method: 'get'
  })
}

export function listOptionalCustomer () {
  return request({
    url: 'customer/listOptionalCustomer',
    method: 'get'
  })
}
